// webpack entry points are allowed to be side effectful
/* eslint-disable tree-shaking/no-side-effects-in-initialization */
import "./css/font.css";
import "./lib/styles/blueprint_overrides.css";
import { FocusStyleManager } from "@blueprintjs/core";
import { enableMapSet } from "immer";
import moment from "moment-timezone";
import momentTimezoneData from "moment-timezone/data/packed/latest.json";
import React from "react";
import { render } from "react-dom";

import App from "./app";
import { CONNECTION_LOG } from "./util/ConnectionLog";
import {
  analyticsEnabled,
  hexBuildTime,
  hexVersion,
  segmentWriteKey,
} from "./util/data";
import { initDatadogLogging } from "./util/initDatadog.js";
import { logErrorMsg } from "./util/logging";
import { TRACE_LOG } from "./util/TraceLog";

enableMapSet();

// eslint-disable-next-line no-console
console.info(`Starting Hex web client
version:    ${hexVersion}
build time: ${hexBuildTime}`);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).__TRACE_LOG__ = TRACE_LOG;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).__CONNECTION_LOG__ = CONNECTION_LOG;

if (analyticsEnabled) {
  try {
    initDatadogLogging();
  } catch (e) {
    console.error("Error initializing Datadog", e);
  }
}

if (segmentWriteKey != null) {
  const SEGMENT_INTEGRATION = {
    integrations: {
      "Segment.io": {
        apiHost: "segment-api.hex.site/v1",
      },
    },
  };
  try {
    window.analytics?.load?.(segmentWriteKey, SEGMENT_INTEGRATION);
  } catch (e) {
    logErrorMsg(e, "Failed to initialize segment");
  }
}

FocusStyleManager.onlyShowFocusOnTabs();
moment.tz.load(momentTimezoneData);

render(<App />, document.getElementById("root"));
